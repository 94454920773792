import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import kusaLeft from '../assets/kusa_left.png'
import kusaRight from '../assets/kusa_right.png'
import unecoStand from '../assets/uneco_stand.png'
import unecoHorizontal from '../assets/uneco_horizontal.png'
import twitterBanner from '../assets/twitter@2x.png'
import flower from '../assets/flower.png'
import ImageFadeIn from 'react-image-fade-in'

const pgtcBanner = 'https://pseudo-girls.technical-committee.com/banners/transparent@2x.png'

const TwitterBanner = styled.div`
position: fixed;
width: 200px;
height: 40px;
background: transparent;
right: 22px;
bottom: 22px;

@media screen and (max-width: 960px) {
  background: #fff;
}
`

interface ContainerProps {
  width: number;
  height: number;
  backgroundColor: string;
}

const Container = styled.div.attrs((props: ContainerProps) => ({
  height: props.height,
  width: props.width,
  backgroundColor: props.backgroundColor,
}))`
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
overflow: hidden;
background-color: ${props => props.backgroundColor};

.image-container {
  position: absolute;

  &.kusa-left {
    left: 20%;
    bottom: 5%;

    img {
      height: ${props => props.height * 0.9}px;
    }
  }

  &.kusa-right {
    right: 20%;
    bottom: 5%;
    img {
      height: ${props => props.height * 0.9}px;
    }
  }

  &.uneco-stand {
    left: 50%;
    bottom: 5%;
    img {
      margin-left: -50%;
      height: ${props => props.height * 0.8}px;
    }
  }

  &.uneco-horizontal {
    @media screen and (max-width: 640px) {
      left: -250px;
    }
    right: 200px;
    bottom: -5px;
    img {
      height: ${props => props.height * 0.6}px;
    }
  }

  &.flower {
    top: 0;
    left: 0;
    img {
      height: ${props => props.height}px;
    }
  }
}
`

const ModeA: React.FC = () => {
  return <>
    <div className="image-container kusa-left">
      <ImageFadeIn src={kusaLeft} className="parts" alt="kusa" />
    </div>
    <div className="image-container kusa-right">
      <ImageFadeIn src={kusaRight} className="parts" alt="kusa" />
    </div>
    <div className="image-container uneco-stand">
      <ImageFadeIn src={unecoStand} className="parts" alt="uneco" />
    </div>
  </>
}

const ModeB: React.FC = () => {
  return <>
    <div className="image-container flower">
      <ImageFadeIn src={flower} className="parts" alt="flower" />
    </div>
    <div className="image-container uneco-horizontal">
      <ImageFadeIn src={unecoHorizontal} className="parts" alt="uneco" />
    </div>
  </>
}

const mode = Math.random() < 0.5 ? 'a' : 'b'
const Banner = styled.img`
  width: 200px;
  height: 40px;
  display: block;
`
const BannerLayout = styled.div.attrs((props: { offset?: number }) => ({
  offset: props.offset || 0,
}))`
  position: absolute;
  left: ${({ offset }) => 44 + offset}px;
  bottom: 22px;
`
const BannerContainer = styled.div.attrs((props: { color?: string }) => ({
  color: props.color || '#fff',
}))`
  background: ${({ color }) => color};
  margin: 0;
  padding: 0;
`

const PGTCBanner: React.FC = () => {
  const blank = '_blank'

  return <BannerContainer color='#fff'>
    <a target={blank} rel='noopener' href='https://pseudo-girls.technical-committee.com'>
      <Banner src={pgtcBanner} alt='PGTC banner' />
    </a>
  </BannerContainer>
}

const Home: React.FC = () => {
  const [width, setWidth] = useState(document.documentElement.clientWidth)
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  const backgroundColor = mode === 'a' ? '#ffffff' : '#ccdccf'

  useEffect(() => {
    const listener = (): void => {
      setWidth(document.documentElement.clientWidth)
      setHeight(document.documentElement.clientHeight)
    }

    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  })

  return (
    <>
      <main className="app-content">
        <Container backgroundColor={backgroundColor} width={width} height={height}>
          { mode === 'a' ? <ModeA /> : <ModeB /> }
          <TwitterBanner>
            <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/unecochan'>
              <Banner src={twitterBanner} alt='twitter banner' />
            </a>
          </TwitterBanner>
          <BannerLayout>
            <PGTCBanner />
          </BannerLayout>
        </Container>
      </main>
    </>
  )
}

export default Home
