import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.scss'
import Home from './pages/Home'

const App: React.FC = () => {
  return (
    <Router>
      <div className="app">
        <div className="app-main">
          <Switch>
            <Route exact path="/" render={() => <Home/>} />
          </Switch>
        </div>
      </div>
    </Router>
  )
}

export default App
